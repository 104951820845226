<template>
  <div class="-mx-10 -mt-8 -mb-32">

    <div class="border-t border-blue-200 flex flex-wrap h-full">

      <div class="w-full md:w-5/10 xl:w-4/10 px-10 pt-10 h-screen overflow-y-auto pb-4">
        <h4 class="text-xl font-bold mb-6">Staff</h4>

        <template v-if="staff.loading">
          <sm-loader class="mb-10"></sm-loader>
        </template>
        <template v-else-if="staff.data.length">
          <div>
            <template v-for="(staff, n) in filteredStaff">
              <div class="flex items-center flex-wrap cursor-pointer border-b border-blue-200 py-4 font-medium" @click.prevent="selectStaff(staff)" :key="n">
                <div class="text-sm w-full mb-1">{{ staff.name }}</div>
                <div class="text-sm w-full text-gray-500">
                  Role{{ staff.roles.length != 1 ? 's' : '' }}: 
                  <b class="font-bold">{{ staff.roles.map(role => role.name).join(', ') }}</b>
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="text-sm">No Staff Available</div>
        </template>

      </div>

      <div class="w-full md:w-5/10 xl:w-6/10 px-10 pt-10 border-l border-blue-200 h-screen overflow-y-auto pb-32">
        <template v-if="roles.loading">
          <sm-loader class="mb-10" />
        </template>

        <template v-else-if="staff.selected">

          <div class="mb-10">
            <h4 class="text-xl font-bold mb-6">Roles</h4>

            <div class="mb-6">
              <div class="mb-4">Admin Roles</div>

              <div class="flex flex-wrap">
                <template v-for="(role, n) in adminRoles">
                  <div class="inline-flex flex-row items-center mb-6 w-full md:w-1/2 lg:w-full xl:w-1/2" :key="n">
                    <checkbox
                      :id="role.slug"
                      class="mr-3"
                      @change="(value) => toggleRole(value, role.slug)"
                      :checked="isSelected(role.slug, form.data.roles.value)"
                      />
                    <label :for="role.slug" class="text-xs">{{ role.name }}</label>
                  </div>
                </template>
              </div>

            </div>

            <div class="mb-6">
              <div class="mb-4">Staff Roles</div>

              <div class="flex flex-wrap">
                <template v-for="(role, n) in staffRoles">
                  <div class="inline-flex flex-row items-center mb-6 w-full md:w-1/2 lg:w-full xl:w-1/2" :key="n">
                    <checkbox
                      :id="role.slug"
                      class="mr-3"
                      @change="(value) => toggleRole(value, role.slug)"
                      :checked="isSelected(role.slug, form.data.roles.value)"
                      />
                    <label :for="role.slug" class="text-xs">{{ role.name }}</label>
                  </div>
                </template>
              </div>
            </div>

          </div>
          <div class="mb-10">
            <h4 class="text-xl font-bold mb-6">Permissions</h4>

            <template v-if="permissions.length">
              <div class="flex flex-wrap mb-10">
                <template v-for="(permission, n) in permissions">
                  <div class="inline-flex flex-row items-center mb-6 w-full md:w-1/2 lg:w-full xl:w-1/2" :key="n">
                    <checkbox
                      :id="permission.slug"
                      class="mr-3"
                      @change="(value) => togglePermission(value, permission.slug)"
                      :checked="isSelected(permission.slug, form.data.permissions.value)"
                    />
                    <label :for="permission.slug" class="text-xs">{{ permission.name }}</label>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="alert alert-orange-soft mb-10">
                <span class="alert-icon">!</span>
                <span>No Permissions Selected!</span>
              </div>
            </template>

            <template v-if="getFormError(form)">
              <div class="alert alert-red-soft mb-10">
                <span class="alert-icon">!</span>
                <span>{{ getFormError(form) }}</span>
              </div>
            </template>
            
            <button
              type="button"
              class="btn btn-blue"
              @click.prevent="updatePermissions"
              :disabled="form.loading"
            >
              <span v-if="form.loading">Saving</span>
              <span v-else>Save Changes</span>
            </button>

          </div>

        </template>

        <template v-else>
          <div class="text text-gray-700">
            Click on a staff member to view and update their roles and permissions.
          </div>
        </template>
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectedStaff: null,
        staff: this.$options.resource([], {query: '', selected: null}),
        roles: this.$options.resource([]),
        form: this.$options.basicForm([{name: 'roles', value: []}, {name: 'permissions', value: []}])
      }
    },
    computed: {
      filteredStaff() {
        return this.staff.data.filter(staff => {
          const regexp = new RegExp(this.staff.query, 'i');
          return staff.name?.match(regexp) || staff.email?.match(regexp) || staff.phone?.match(regexp) || staff.profile?.bvn?.match(regexp);
        });
      },
      adminRoles() {
        return this.roles.data.filter( role => !role.slug.match(/^company_(staff|department_head|unit_head)$/) );
      },
      staffRoles() {
        return this.roles.data.filter( role => role.slug.match(/^company_(staff|department_head|unit_head)$/) );
      },
      permissions() {
        var permissions = [];

        this.form.data.roles.value.forEach(slug => {
          let role = this.roles.data.find(r => r.slug === slug);
          permissions = [
            ...permissions,
            ...role.permissions
          ]
        });

        return permissions;
      }
    },
    beforeMount() {
      this.getStaff();
      this.getRoles();
    },
    mounted() {
    },
    watch: {
      'form.data.roles.value'(value) {
        var permissions = [];

        value.forEach(slug => {
          let role = this.roles.data.find(r => r.slug === slug);
          permissions = [
            ...permissions,
            ...role.permissions
          ]
        });

        this.form.data.permissions.value = permissions.map(permission => permission.slug);
      }
    },
    methods: {
      selectStaff(staff) {
        this.staff.selected = staff;
        this.form.data.roles.value = staff.roles.filter(role => !role.slug.match(/super_/)).map(role => role.slug);
        // this.form.data.permissions.value = staff.permissions.map(permission => permission.slug);
      },
      toggleRole(value, role) {
        if (value) {
          if (this.form.data.roles.value.indexOf(role) == -1) {
            this.form.data.roles.value.push(role);
          }
        }else {
          this.form.data.roles.value = this.form.data.roles.value.filter( r => r != role );
        }
      },
      togglePermission(value, permission) {
        if (value) {
          if (this.form.data.permissions.value.indexOf(permission) == -1) {
            this.form.data.permissions.value.push(permission);
          }
        }else {
          this.form.data.permissions.value = this.form.data.permissions.value.filter( r => r != permission );
        }
      },
      isSelected(key, array = []) {
        return array.indexOf(key) > -1;
      },
      async getStaff() {
        this.staff.loading = true;

        await this.sendRequest('corporate.permissions.users', {
          success: response => {
            this.staff.data = response.data.data;
            this.staff.data = this.staff.data.map(staff => ({
              ...staff,
              roles: staff.roles.filter(role => !role.slug.match(/super_/)),
              permissions: staff.permissions.filter(permission => !permission.slug.match(/(credpal|personal):/))
            }))
          },
          error: error => {
            this.staff.error = error;
          }
        });

        this.staff.loading = false;
      },
      async getRoles() {
        this.roles.loading = true;

        await this.sendRequest('corporate.permissions.roles', {
          success: response => {
            this.roles.data = response.data.data;
          },
          error: error => {
            this.roles.error = error;
          }
        });

        this.roles.loading = false;
      },
      async updatePermissions() {
        this.form.loading = true;
        this.form.error = false;

        await this.sendRequest('corporate.permissions.update', {
          data: {
            ...this.getFormData(this.form),
            user_id: this.staff.selected?.id
          },
          success: () => {
            this.getStaff();
          },
          error: error => {
            this.form.error = error;
          }
        });

        this.form.loading = false;
      }
    }
  }
</script>